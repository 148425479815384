/*
 * @Author: limit
 * @Date: 2023-07-19 10:35:48
 * @LastEditTime: 2025-03-21 21:51:18
 * @LastEditors: lusy lusy@glodon.com
 * @FilePath: \portal-web\src\util\tools.ts
 * @Description: 由limit创建！
 */
import { QuerySchemeConditionType } from '@/api/type';
import axios from 'axios';
import { httpHeaders } from './http';
import { downloadFile, previewFile } from '@/api/common';
import { SUCCESS_STATUS } from './constants';
import { ElMessage } from 'element-plus';

type ArrayToTreeOptions = {
  rootId?: string; // 根节点的id
  pid?: string; // 父id的标识字段
  id?: string; // 节点的唯一标识字段
  children?: string; // 子节点的集合字段
};

/**
 ** 数组转树结构
 * @param array  需要转树的数组
 * @param options  自定义转换标识的字段
 * @returns Object 转换后的树
 */
export const arrayToTree = <T extends IRecord>(
  array: Array<T>,
  options?: ArrayToTreeOptions
): Array<T> => {
  const initOption: Required<ArrayToTreeOptions> = Object.assign(
    {
      rootId: '-1',
      pid: 'pid',
      id: 'id',
      children: 'children',
    },
    options || {}
  );
  // 建立了id=>node的映射
  const objMap = array.reduce(
    // map-累加器，node-当前值
    (map: IRecord, node: IRecord) => {
      map[node[initOption.id]] = node;
      node[initOption.children] = [];
      return map;
    },
    // 初始值
    {}
  );
  return array?.filter((node) => {
    // 寻找父元素的处理：
    // 1. 遍历list：时间复杂度是O(n)，而且在循环中遍历，总体时间复杂度会变成O(n^2)
    // 2. 对象取值：时间复杂度是O(1)，总体时间复杂度是O(n)
    objMap[node[initOption.pid]] &&
      objMap[node[initOption.pid]].children.push(node);
    // 根节点没有pid，可当成过滤条件
    return !node[initOption.pid] || node[initOption.pid] === initOption.rootId;
  });
};

export type DateParams = Date | string | number;
export type TimeStackDesc = {
  day: string;
  hour: string;
  min: string;
  second: string;
};

/**
 ** 将传入的时间戳转化为几天几时几分几秒
 * @param diff Number 要转换的时间戳，单位毫秒
 * @returns TimeStackDesc 转换后的对象
 */
export const getTimeCountDown = (diff: number) => {
  const timeStack = {} as TimeStackDesc;
  diff = diff / 1000;
  timeStack.day = String(Math.floor(diff / 86400)).padStart(2, '0');
  diff %= 86400;
  timeStack.hour = String(Math.floor(diff / 3600)).padStart(2, '0');
  diff %= 3600;
  timeStack.min = String(Math.floor(diff / 60)).padStart(2, '0');
  timeStack.second = String(Math.floor(diff % 60)).padStart(2, '0');
  return timeStack;
};

/**
 ** 格式化日期
 * @param template String // 格式
 * @param date DateParams // 要转化的时间
 * @returns String  // 格式化后的字符串
 */
export const formatDate = (
  template: string = 'YYYY-MM-DD HH:mm:ss',
  date: DateParams = new Date()
) => {
  const specs = 'YYYY:MM:DD:HH:mm:ss'.split(':');
  date = new Date(+new Date(date) - new Date().getTimezoneOffset() * 6e4);
  return date
    .toISOString()
    .split(/[-:.TZ]/)
    .reduce(
      (template, item, i) => template.split(specs[i]).join(item),
      template
    );
};

/**
 ** 深度克隆数组或对象
 * @param source [ IRecord | Array<any> ] // 需要转换的原数据
 * @returns // 返回转换后的数据
 */
export const deepClone = <T extends IRecord>(source: T): T =>
  JSON.parse(JSON.stringify(source));

/**
 ** JS对象深度合并
 * @param target <Object> 目标对象
 * @param source <Object> 源对象
 * @returns Object 合并后的对象
 */
export const deepMerge = <T extends Record<string, any>>(
  target: T,
  source: T
): T => {
  target = deepClone(target);
  if (
    typeof target !== 'object' ||
    target === null ||
    typeof source !== 'object' ||
    source === null
  ) {
    return target;
  }

  const merged = (
    Array.isArray(target) ? target.slice() : Object.assign({}, target)
  ) as any;
  for (const key in source) {
    if (!Object.hasOwnProperty.call(source, key)) continue;
    const sourceValue: any = source[key];
    const targetValue = merged[key];
    if (typeof sourceValue === 'object' && sourceValue !== null) {
      merged[key] = deepMerge(targetValue, sourceValue);
    } else {
      merged[key] = sourceValue;
    }
  }
  return merged;
};

// 判断是否为Event对象
export const isEventObj = (e: any): boolean => {
  return Object.prototype.toString.call(e) === '[object Event]';
};

/**
 * 通过iframe下载文件；
 * @param {Attachment | string} file  文件对象，包含id和name 或者直接传url
 */
export const downloadFileFn = (file: Blob, filename: string = ''): void => {
  if ((window as any).navigator.msSaveOrOpenBlob) {
    (window as any).navigator.msSaveBlob(file, filename);
  } else {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = window.URL.createObjectURL(file); // 创建对象url
    link.download = filename;
    // fix Firefox
    if (body) {
      link.style.display = 'none';
      body.appendChild(link);
      link.click();
      body.removeChild(link);
    }
    window.URL.revokeObjectURL(link.href); // 通过调用 URL.createObjectURL() 创建的 URL 对象
  }
};

/**
 * 转换对象格式为查询需要的参数格式
 * @param params Object 需要转换的对象
 * @param likeKey Array<string> 需要模糊查询的key
 * @returns Array<QuerySchemeConditionType>
 */
export const handleSchemeParams = <T>(
  params: IRecord,
  likeKey?: Array<string>
): Array<QuerySchemeConditionType<T>> => {
  const condition = [];
  for (const key in params) {
    if (params[key]) {
      const schemeItem = {
        queryKey: key,
        oValue: params[key],
        operater: '=',
        relation: 'and',
      };
      if (key === 'keyword') {
        if (Array.isArray(likeKey)) {
          const oValue: IRecord[] = [];
          likeKey.forEach((key) => {
            oValue.push({
              queryKey: key,
              oValue: params.keyword,
              operater: 'like',
              relation: 'or',
            });
          });
          condition.push({
            queryKey: '',
            oValue,
            operater: '=',
            relation: 'and',
          });
        } else {
          // 全部搜索
          schemeItem.operater = 'like';
          condition.push(schemeItem);
        }
      } else {
        if (
          Array.isArray(params[key]) &&
          (key.endsWith('Date') ||
            key.endsWith('Time') ||
            key.endsWith('Deadline'))
        ) {
          params[key].forEach((item: string, index: number) => {
            const cloneScheme = { ...schemeItem };
            cloneScheme.oValue = item + `${index ? ' 23:59:59' : ' 00:00:00'}`;
            cloneScheme.operater = index ? '<=' : '>=';
            condition.push(cloneScheme);
          });
        } else {
          condition.push(schemeItem);
        }
      }
    }
  }

  return condition;
};

export const globalSearchSchemeParams = <T>(
  params: IRecord,
  type: string,
  allType?: string
): Array<IRecord> => {
  const condition = [];
  for (const key in params) {
    const schemeItem = {
      queryKey: key,
      oValue: params[key],
      operater: 'like',
      relation: 'and',
    };
    const typeItem = {
      queryKey: 'type',
      oValue: type,
    };
    condition.push(schemeItem);
    if (type !== 'all') {
      condition.push(typeItem);
    } else {
      condition.push({
        queryKey: 'type',
        oValue: allType,
      });
    }
  }
  return condition;
};

/**
 * 函数“replaceStringToVar”将字符串中的占位符替换为给定上下文对象中的相应值。
 * @param {string} str - “str”参数是一个字符串，其中包含“{key}”形式的占位符。这些占位符将替换为“context”对象中的相应值。
 * @param {IRecord} context - `context` 参数是一个 `IRecord` 类型的对象。它用于存储键值对，其中键是字符串，值可以是任何类型。
 */
export const replaceStringToVar = (str: string, context: IRecord) =>
  str.replace(/{(.*?)}/g, (match, key) => context[key.trim()]);

// 设置请求头
export function setAxiosDefaultHeaders(token: string) {
  const header = {
    Authorization: `bearer ${token}`,
  };
  Object.assign(axios.defaults.headers.common, header);
}
// 下载
export function downloadFileByIframe(src: string) {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.style.height = '0';
  iframe.src = src;
  document.body.appendChild(iframe);
  setTimeout(() => {
    iframe.remove();
  }, 10000);
}
export function convertToAtt(summaryMap: IRecord) {
  const attachsArr = [];
  if (summaryMap) {
    const attachs = summaryMap.attachments;
    if (attachs) {
      const keys = Object.keys(attachs);
      for (const key of keys || []) {
        attachsArr.push(...attachs[key]);
      }
    }
  }
  return attachsArr;
}

export function beforePreview(file: IRecord) {
  const fileType = file.name
    .substring(file.name.lastIndexOf('.') + 1)
    .toLowerCase();
  const headers = {
    'x-org-id': file.orgId,
    'x-product-code': file.productCode,
    'x-tenant-id': file.tenantId,
  };
  if (
    fileType === 'png' ||
    fileType === 'pdf' ||
    fileType === 'jpg' ||
    fileType === 'jpeg' ||
    fileType === 'gif' ||
    fileType === 'tif' ||
    fileType === 'psd' ||
    fileType === 'bmp' ||
    fileType === 'txt'
  ) {
    previewFile(file.fileId, headers).then((res) => {
      if (res.status === SUCCESS_STATUS) {
        window.open(res.data);
      }
    });
  } else {
    ElMessage.warning('该文件类型不支持预览, 请下载查看');
  }
}
export function downloadFilefun(file: IRecord) {
  const headers = {
    'x-org-id': file.orgId,
    'x-product-code': file.productCode,
    'x-tenant-id': file.tenantId,
  };
  downloadFile(file.fileId, headers).then((res) => {
    if (res.status === SUCCESS_STATUS) {
      downloadFileByIframe(res.data);
    }
  });
}
export function dealDate(cur: string) {
  if (cur) {
    const date = cur.split(' ')[0];
    return date
      .split('-')
      .filter((i, idx) => idx > 0)
      .join('/');
  }
  return '--';
}

export function getYMD(dateStr: string) {
  let ret = '--';
  if (dateStr?.length > 10) {
    ret = dateStr.substring(0, 10);
  }
  return ret;
}

export function getTaskStatus(type: 'status' | 'operate', row: IRecord) {
  // 取summaryMap.tender.active中的procState，如果是98结尾的就是流标，99结尾的是废标
  const { bidDeadline, summaryMap } = row;

  if (summaryMap?.active?.procState.at(-1) === '8') {
    return {
      text: '已流标',
      disabled: true,
    };
  } else if (summaryMap?.active?.procState.at(-1) === '9') {
    return {
      text: '已废标',
      disabled: true,
    };
  }
  if (+new Date(bidDeadline) > new Date().getTime()) {
    const ret = {
      text: type === 'status' ? '进行中' : '立即报名',
      disabled: false,
    };
    return ret;
  }
  return {
    text: '已截止',
    disabled: true,
  };
}
export const findField = (str: string, rootObj: IRecord) => {
  const arr = str.split('.');
  let res: string = '--';
  const subFn = (obj: IRecord, curIndex: number) => {
    if (curIndex === arr.length - 1) {
      res = obj[arr[curIndex]] ? obj[arr[curIndex]] : '--';
      return;
    }

    if (obj[arr[curIndex]]) {
      subFn(obj[arr[curIndex]], curIndex + 1);
    } else {
      res = '--';
    }
  };
  if (Object.keys(rootObj || {}).length > 0) {
    subFn(rootObj, 0);
  }
  return res;
};
export const findAttrField = (str: string, rootObj: IRecord) => {
  const arr = str.split('.');
  let res: IRecord = {};
  const subFn = (obj: IRecord, curIndex: number) => {
    if (curIndex === arr.length - 1) {
      res = obj[arr[curIndex]] ? obj[arr[curIndex]] : {};
      return;
    }

    if (obj[arr[curIndex]]) {
      subFn(obj[arr[curIndex]], curIndex + 1);
    } else {
      res = {};
    }
  };
  if (Object.keys(rootObj || {}).length > 0) {
    subFn(rootObj, 0);
  }
  console.log('res', res);
  return res;
};

export const getCookieInfo = (name: string) => {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=');
    if (cookie[0] === name) {
      return cookie[1];
    }
  }
};
