/*
 * @Author: limit
 * @Date: 2023-07-19 10:35:48
 * @LastEditTime: 2025-03-20 09:05:59
 * @LastEditors: lusy lusy@glodon.com
 * @FilePath: \portal-web\src\App.tsx
 * @Description: 由limit创建！
 */
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  PropType,
  provide,
  ref,
  watch,
} from 'vue';
import { useAsyncState } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { ElConfigProvider, ElMessage, ElScrollbar } from 'element-plus';
import { zhCn } from 'element-plus/es/locale/index';
import Header from '@/components/common/header';
import Footer from '@/components/common/footer';
import { getObjTypeDicttApi } from './api/common';
import { SUCCESS_STATUS } from './util/constants';
import { getCanvas, getFooterInfo } from './api/editTemplate';
import { useStore } from 'vuex';
import { COMPS_TYPES } from './components/homepage/config';
import { MACHINE, SET_MACHINE } from './store/machine/const';
import { useUserInfo } from '@/hooks/useUserInfo';
import { useElementPlusTheme } from 'use-element-plus-theme';
const { changeTheme } = useElementPlusTheme();

export default defineComponent({
  name: 'App',
  props: {
    route: {
      type: Object as PropType<IRecord>,
      required: false,
    },
  },
  setup(props, ctx) {
    const route = useRoute();

    const { state: stateSore, dispatch } = useStore();
    useUserInfo();

    // 这块貌似无用
    const debounce = (callback: (...args: any[]) => void, delay: number) => {
      let tid: any;
      return function (...args: any[]) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(ctx, args);
        }, delay);
      };
    };

    const _ = (window as any).ResizeObserver;
    (window as any).ResizeObserver = class ResizeObserver extends _ {
      constructor(callback: (...args: any[]) => void) {
        callback = debounce(callback, 200);
        super(callback);
      }
    };

    const canvasLoading = ref(false);

    // 获取画布初始数据渲染
    const getCanvasInfo = async () => {
      canvasLoading.value = true;

      const params: IRecord = {};

      const state: IRecord = { layouts: [] };

      if (route.query.templateId) {
        params.templateId = route.query.templateId;
        provide('isCanvasReadonly', true); // 可拖动, 预览状态, 不能点击, 接口加source,接口传templateId
      } else {
        provide('isCanvasReadonly', false);
      }
      const { status, data } = await getCanvas(params);
      if (status === SUCCESS_STATUS) {
        state.layouts.length = 0;
        data.coms?.forEach((item: IRecord, index: number) => {
          item.meta = JSON.parse(item.meta);
          const itemCopy = JSON.parse(JSON.stringify(item));
          item.layout = JSON.parse(item.layout);
          item.layout.i = index;
          item.layout.itemCopy = itemCopy;
          state.layouts.push(item.layout);
        });
        // 获取不同模块例如头部等的数据
        dealData(data.coms);
        if (data?.themeColor && data?.themeColor.isUserDefine) {
          const color = data.themeColor.mainColor;
          changeTheme(color);
        } else {
          setDefaultColor();
        }
      }
      canvasLoading.value = false;
    };

    const setDefaultColor = () => {
      const defaultThemeColor: IRecord = {
        '--el-color-primary': '#001a80',
        '--el-color-primary-light-7': '#1b4cb8',
        '--gld-nav-bg': '#003e80',
        '--el-color-primary-light-3': '#0058b7',
        '--gld-footer-bg': '#192638',
      };
      // 可以直接使用for in迭代
      const keys = Object.keys(defaultThemeColor);
      for (const key of keys) {
        document.documentElement.style.setProperty(key, defaultThemeColor[key]);
      }
    };
    getCanvasInfo();
    watch(
      () => route?.query.templateId,
      (nv: any, ov: any) => {
        if (nv && nv !== ov) {
          getCanvasInfo();
        }
      },
      { immediate: true, deep: true }
    );

    const getObjTypeDict = async () => {
      const { status, data, message } = await getObjTypeDicttApi();
      if (status === SUCCESS_STATUS && Array.isArray(data)) {
        const objTypesDict = data.map((item: IRecord) => ({
          label: item.objTypeName,
          value: item.objTypeCode,
        }));
        dispatch(`${MACHINE}/${SET_MACHINE}`, { objTypesDict });
      }
    };
    // 获取标的（招标）类型
    getObjTypeDict();
    const dealData = (data: any) => {
      for (const cur of data) {
        if (cur.type === COMPS_TYPES.HEADER_WIDGET) {
          dispatch(`${MACHINE}/${SET_MACHINE}`, {
            headerWidgetData: cur,
          });
        }

        if (cur.type === COMPS_TYPES.NAVIGATION) {
          dispatch(`${MACHINE}/${SET_MACHINE}`, {
            navBarData: cur,
          });
        }

        if (cur.type === COMPS_TYPES.FOOTER_WIDGET) {
          dispatch(`${MACHINE}/${SET_MACHINE}`, {
            footerWidgetData: cur,
          });
        }
      }
    };

    const getFavicon = () => {
      const link: any = document.querySelector("link[rel*='icon']");
      link.rel = 'icon';
      link.href = '/portal/favicon.ico';
      document.getElementsByTagName('head')[0].appendChild(link);
    };

    const setKeyword = (headerInfo: any) => {
      if (headerInfo?.seoKeywords) {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'keyword');
        meta.setAttribute('content', headerInfo?.seoKeywords);
        document.head.appendChild(meta);
      }
      if (headerInfo?.name) {
        const title: any = document.querySelector('title');
        title.innerHTML = headerInfo?.name;
      }
    };

    const getFooterInfoFun = async () => {
      const params = {
        templateId: props.route?.query.templateId || route.query.templateId,
        source:
          props.route?.query.templateId || route.query.templateId
            ? 'preview'
            : '',
      };
      const { status, data } = await getFooterInfo(params);
      if (status === SUCCESS_STATUS) {
        dispatch(`${MACHINE}/${SET_MACHINE}`, {
          baseInfo: data,
        });

        if (data) {
          setKeyword(data.headerInfo);
        }
      }
    };

    onMounted(() => {
      getFavicon();
      getFooterInfoFun();
      console.log('已挂载');
    });

    return () => (
      <ElConfigProvider locale={zhCn}>
        <ElScrollbar height="100vh" view-class="min-h-100 d-flex flex-column">
          {ctx.slots.header
            ? ctx.slots.header()
            : route.path !== '/' && <Header />}
          <div class="flex-1 min-h-50">
            <router-view key={props.route?.path || route.path} />
          </div>
          {ctx.slots.footer
            ? ctx.slots.footer()
            : route.path !== '/' && <Footer />}
        </ElScrollbar>
      </ElConfigProvider>
    );
  },
});
