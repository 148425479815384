/*
 * @Author: limit
 * @Date: 2023-07-17 17:44:59
 * @LastEditTime: 2025-03-04 18:06:56
 * @LastEditors: lusy lusy@glodon.com
 * @FilePath: \portal-web\src\main.ts
 * @Description: 由limit创建！
 */
import { createApp, watch } from 'vue';
import App from '@/App';
import routes from '@/router';
import store from '@/store';
import '@/style/theme.less';
import 'normalize.css/normalize.css';
import '@/style/index.less';
// import '@/router/guard';
import VueGridLayout from 'vue-grid-layout';
// import { ElLoading } from 'element-plus';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/theme-chalk/index.css';
import { createRouter, createWebHistory, Router } from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/css/nprogress.css';

const app = createApp(App);
const router: Router = createRouter({
  routes: routes,
  scrollBehavior: () => ({
    top: 0,
  }),
  history: createWebHistory(),
});

NProgress.configure({ showSpinner: false });

// 路由守卫
router.beforeEach(async (to, from, next) => {
  NProgress.start();

  // 从当前页面的 URL 中获取 templateId
  const urlParams = new URLSearchParams(window.location.search);
  const templateId = urlParams.get('templateId');

  // 检查是否已经有 templateId 参数
  if (templateId && !to.query.templateId) {
    // 克隆当前的 query 对象
    const query = { ...to.query, templateId };
    // 使用 next 方法重定向到带有 templateId 参数的目标路由
    next({ ...to, query });
  } else {
    // 如果已经有 templateId 参数，直接继续
    next();
  }
});
// 路由加载后；
router.afterEach(() => {
  NProgress.done();
});
app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(VueGridLayout);

app.mount('#app');
